import Vue from 'vue';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import store from "./state/store";
import { vMaska } from "maska";
import "@vueform/multiselect/themes/default.css"

import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import CKEditor from '@ckeditor/ckeditor5-vue';
import BootstrapVueNext from 'bootstrap-vue-next';

import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import './assets/scss/app.scss';
import axios from './plugins/axios';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import Echo from 'laravel-echo';

import Pusher from 'pusher-js';
declare global {
  interface Window {
      Echo: any;
      Pusher: any;
  }
}

window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_APP_KEY,  
  wsHost: process.env.VUE_APP_PUSHER_HOST, 
  wsPort: process.env.VUE_APP_PUSHER_PORT,
  cluster: 'us2',
  forceTLS: false,
  disableStats: false,
  enabledTransports: ['ws', 'wss'], // Use 'ws' for non-secure, 'wss' for secure
  reconnect: {
      maxAttempts: 120,  // Max number of reconnection attempts
      delay: 0,      // Time to wait between reconnection attempts
      randomizationFactor: 0.5,  // Randomize delay by a factor
  },
  maxReconnectAttempts: 120, // Control retries
});



  // Listen for connection events (connect, disconnect, etc.)
  window.Echo.connector.pusher.connection.bind('connected', () => {
    resubscribeToAllChannels();
      console.log('Connected to WebSocket server!');
  });

  window.Echo.connector.pusher.connection.bind('disconnected', () => {
      console.log('Disconnected from WebSocket server!');
      reconnectAndResubscribe();
  });

  window.Echo.connector.pusher.connection.bind('error', (error) => {
      console.error('WebSocket error:', error);
  });

  // Optionally, bind to other Pusher connection states
  window.Echo.connector.pusher.connection.bind('state_change', (states) => {
      console.log('Connection state changed:', states);
  });

const subscribedEvents = new Set();
const channelSubscriptions = new Map(); // Track active subscriptions

export const subscribeToChannel = (channelName: string, eventName: string, callback: Function) => {
  // Create a unique identifier for the channel and event
  const subscriptionKey = `${channelName}:${eventName}`;

  // Check if the channel and event combination is already subscribed
  if (subscribedEvents.has(subscriptionKey)) {
      console.debug("Already subscribed to", subscriptionKey);
      return; // Skip if already subscribed
  }

  // Subscribe to the channel and listen to events
  const channel = window.Echo.channel(channelName)
      .listen(`.${eventName}`, (data) => {
          callback(data); // Call the provided callback with the event data
      });


  // Mark the channel and event as subscribed
  subscribedEvents.add(subscriptionKey);
// Store the subscription in the channelSubscriptions map
channelSubscriptions.set(subscriptionKey, channel);
  console.debug("Subscribed to", subscriptionKey);
};

export const unsubscribeFromChannel = (channelName: string, eventName: string) => {
  const subscriptionKey = `${channelName}:${eventName}`;

  // Check if the subscription exists
  if (!subscribedEvents.has(subscriptionKey)) {
      console.debug("Not subscribed to", subscriptionKey);
      return; // Skip if not subscribed
  }

  // Unsubscribe from the channel
  const channel = channelSubscriptions.get(subscriptionKey);
  if (channel) {
      channel.unsubscribe();
      channelSubscriptions.delete(subscriptionKey); // Remove from the active subscriptions map
      console.debug("Unsubscribed from", subscriptionKey);
  }

  // Remove from the set of subscribed events
  subscribedEvents.delete(subscriptionKey);
};
export const reconnectAndResubscribe = () => {
  // Reconnect to Pusher and resubscribe to all previously subscribed channels
  if (window.Echo.connector.pusher.connection.state === 'disconnected') {
      console.log('Attempting to reconnect...');
      window.Echo.connector.pusher.connect(); // Trigger reconnection
  }
  resubscribeToAllChannels();
};


export const resubscribeToAllChannels = () => {
    const copiedEvents = new Set(subscribedEvents);

    // Clear the original set
    subscribedEvents.clear();

   // After reconnect, resubscribe to all channels
   copiedEvents.forEach((subscriptionKey: string) => {
    const [channelName, eventName] = subscriptionKey.split(":");
    subscribeToChannel(channelName, eventName, (data: any) => {
        console.log("Resubscribed to event:", eventName, data);
    });
});
};

  

export const vueInstance = createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVueNext)
    .use(i18n)
    .directive("maska", vMaska)
    .use(CKEditor)
    .use(axios, {
        baseUrl: 'http://' + process.env.VUE_APP_API_BASE_URL || 'http://localhost/api',
        token: localStorage.getItem('jwt')
    })
    .use(vClickOutside)
    .component('VueDatePicker', VueDatePicker)
    .mount('#app');